// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* Media queries for responsiveness */
.check-grid-section{
  margin-top: 40px;
}
/* For tablets and smaller desktop screens */
@media (max-width: 1200px) {
  .card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(33.33% - 10px);
  }
}

/* For mobile screens */
@media (max-width: 768px) {
  .grid-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .view-all-button {
    margin-top: 10px;
  }

  .card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 10px); /* Display 2 cards per row */
  }
}

/* For small mobile screens */
@media (max-width: 480px) {
  .card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 10px); /* Display 2 cards per row */
  }
  
  .check-grid-section{
    margin-top: 10px;
    padding: 10px;
  }

  .grid-header {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .grid-title {
    text-align: left;
    width: 100%;
  }

  .view-all-button {
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }

  .card-container {
    flex-direction: row;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CheckGridSection.css"],"names":[],"mappings":";;AAEA,qCAAqC;AACrC;EACE,gBAAgB;AAClB;AACA,4CAA4C;AAC5C;EACE;IACE,YAA6B;IAA7B,cAA6B;IAA7B,+BAA6B;EAC/B;AACF;;AAEA,uBAAuB;AACvB;EACE;IACE,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B,EAAE,4BAA4B;EAC1D;AACF;;AAEA,6BAA6B;AAC7B;EACE;IACE,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B,EAAE,4BAA4B;EAC1D;;EAEA;IACE,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,mBAAmB;EACrB;AACF","sourcesContent":["\n\n/* Media queries for responsiveness */\n.check-grid-section{\n  margin-top: 40px;\n}\n/* For tablets and smaller desktop screens */\n@media (max-width: 1200px) {\n  .card {\n    flex: 0 1 calc(33.33% - 10px);\n  }\n}\n\n/* For mobile screens */\n@media (max-width: 768px) {\n  .grid-header {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .view-all-button {\n    margin-top: 10px;\n  }\n\n  .card {\n    flex: 0 1 calc(50% - 10px); /* Display 2 cards per row */\n  }\n}\n\n/* For small mobile screens */\n@media (max-width: 480px) {\n  .card {\n    flex: 0 1 calc(50% - 10px); /* Display 2 cards per row */\n  }\n  \n  .check-grid-section{\n    margin-top: 10px;\n    padding: 10px;\n  }\n\n  .grid-header {\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n  }\n\n  .grid-title {\n    text-align: left;\n    width: 100%;\n  }\n\n  .view-all-button {\n    text-align: center;\n    width: 100%;\n    margin-top: 10px;\n  }\n\n  .card-container {\n    flex-direction: row;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
