// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-grid-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.location-grid-header {
  text-align: left; /* Aligns the "รีวิว" text to the left */
  margin-bottom: 10px;
}

.location-grid-divider {
  width: 100%;
  margin-bottom: 20px;
}

.video-wrapper {
  display: flex;
  justify-content: space-between; /* Aligns the videos to the left and right */
  width: 100%;
  gap: 10px; /* Adds a 10px gap between the videos */
}

.video-container {
  width: 48%; /* Each video takes up 48% of the container width */
}

iframe {
  width: 100%;
  height: 315px;
}

@media (max-width: 768px) {

  .video-grid-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }
  
  .video-wrapper {
    flex-direction: column; /* Stacks videos vertically on smaller screens */
  }

  .video-container {
    width: 100%;
    margin-bottom: 10px; /* Adds space between videos on smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/VideoSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB,EAAE,wCAAwC;EAC1D,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B,EAAE,4CAA4C;EAC5E,WAAW;EACX,SAAS,EAAE,uCAAuC;AACpD;;AAEA;EACE,UAAU,EAAE,mDAAmD;AACjE;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;;EAEE;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,sBAAsB,EAAE,gDAAgD;EAC1E;;EAEA;IACE,WAAW;IACX,mBAAmB,EAAE,iDAAiD;EACxE;AACF","sourcesContent":[".video-grid-section {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.location-grid-header {\n  text-align: left; /* Aligns the \"รีวิว\" text to the left */\n  margin-bottom: 10px;\n}\n\n.location-grid-divider {\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.video-wrapper {\n  display: flex;\n  justify-content: space-between; /* Aligns the videos to the left and right */\n  width: 100%;\n  gap: 10px; /* Adds a 10px gap between the videos */\n}\n\n.video-container {\n  width: 48%; /* Each video takes up 48% of the container width */\n}\n\niframe {\n  width: 100%;\n  height: 315px;\n}\n\n@media (max-width: 768px) {\n\n  .video-grid-section {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 10px;\n  }\n  \n  .video-wrapper {\n    flex-direction: column; /* Stacks videos vertically on smaller screens */\n  }\n\n  .video-container {\n    width: 100%;\n    margin-bottom: 10px; /* Adds space between videos on smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
