// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/MapComponent.css */
.directions-button {
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #006400;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .directions-button:hover {
    background-color: #0c9c0c;;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MapComponent.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/components/MapComponent.css */\n.directions-button {\n    margin: 10px 0;\n    padding: 10px 20px;\n    background-color: #006400;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  .directions-button:hover {\n    background-color: #0c9c0c;;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
